import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c6fc3b1c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "fileList"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_progress = _resolveComponent("el-progress");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.isButton ? 'el-button uploadButton' : '')
  }, [_createVNode(_component_el_upload, {
    ref: "uploadRef",
    class: "avatar-uploader",
    drag: "",
    multiple: $props.multiple,
    "show-file-list": $props.showFileList,
    limit: $props.limit,
    "before-upload": $options.handleBeforeUpload,
    "http-request": $options.requestFile,
    "on-remove": $options.removeFile,
    "on-exceed": $options.uploadExceed,
    "on-change": $options.uploadChange
  }, _createSlots({
    default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
      id: "myBtn",
      class: "left"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.buttonText), 1)]),
      _: 1
    }, 512), [[_vShow, _ctx.loadProgress != 0 && _ctx.loadProgress != 100 && _ctx.progressFlag && $props.file_type == 'file']])]),
    _: 2
  }, [$props.isButton ? {
    name: "trigger",
    fn: _withCtx(() => [_createVNode(_component_el_button, {
      type: $props.buttonThemeType,
      plain: $props.plain,
      loading: _ctx.loadProgress != 0 && _ctx.loadProgress != 100 ? true : false
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
          style: {
            "width": "20px",
            "height": "20px"
          }
        }))]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString($props.uploadTitle), 1)]),
      _: 1
    }, 8, ["type", "plain", "loading"])]),
    key: "0"
  } : {
    name: "trigger",
    fn: _withCtx(() => [$props.isShow && _ctx.imgUrl ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: _ctx.imgUrl,
      class: "avatar"
    }, null, 8, ["src"])) : _createCommentVNode("", true), !_ctx.imgUrl && !$props.isButton ? (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      class: "avatar-uploader-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_Plus)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    key: "1"
  }]), 1032, ["multiple", "show-file-list", "limit", "before-upload", "http-request", "on-remove", "on-exceed", "on-change"]), _withDirectives(_createVNode(_component_el_progress, {
    percentage: _ctx.loadProgress
  }, null, 8, ["percentage"]), [[_vShow, _ctx.progressFlag && _ctx.loadProgress != 0 && _ctx.loadProgress != 100]]), $props.showFileList && _ctx.fileList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index
    }, _toDisplayString(item.name), 1);
  }), 128))])) : _createCommentVNode("", true)], 2);
}