import "core-js/modules/es.array.push.js";
import store from './../store/index.js';
export default {
  name: 'menuLeft',
  components: {},
  data: function () {
    return {
      //clientHeight:'',
      //tabValue:'',
      //is_path:'',
    };
  },
  created: function () {
    //this.clientHeight = document.documentElement.clientHeight+'px'  
    store.commit('addTabs', {
      title: this.$route.meta.title,
      name: this.$route.path
    });
    // var components = this.$options.components;
    // for (const [key, component] of Object.entries(Icons)) {
    // 	components[`${key}`] = component;
    // }
    // this.$options.components = components;
  },

  props: {
    clientHeight: {
      type: String,
      value: ''
    },
    isCollapse: {
      type: Boolean,
      value: false
    },
    menuLeftWidth: {
      type: String,
      value: ''
    },
    isNest: {
      type: Boolean,
      default: false
    },
    routes: {
      type: Array
    }
  },
  computed: {
    is_path: {
      get: function () {
        var is_path = store.state.is_path;
        if (!is_path) {
          return this.$route.path;
        }
        return is_path;
      }
    }
  },
  methods: {
    goPage: function (page, name) {
      store.commit('addTabs', {
        title: name,
        name: page
      });
      this.$router.push(page);
    },
    handleOpen: function () {},
    handleClose: function () {}
  }
};