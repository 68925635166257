import MD5 from "js-md5";  //导入
function placeholderPic(){
	document.documentElement.style.fontSize=document.documentElement.offsetWidth/60+"px"; //同上
}
function isLogin(){
	var isLogin = localStorage.getItem('isLogin');
	if(isLogin){
		return true;
	}else{
		return false;
	}
}
function platForm(){
	var u = navigator.userAgent;
	var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
	var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
	var platForm = 'pc';
	if(isAndroid){
	    console.log("android");
		platForm = 'android';
	}else if(isIOS){
		platForm = 'ios';
	    console.log("ios");
	}
	return platForm;
	
}
function trimSpace(array){  
	if(!Array.isArray(array)){
		return [];
	}
	var len = array.length;
     for(var i = 0 ;i<len;i++)  
     {  
         if(array[i] == " " || array[i] == null || typeof(array[i]) == "undefined")  
         {  
			  array.splice(i,1);  
			  i= i-1;  
         }  
     }  
     return array;  
}
function alphabets(){
	return ['A','B','C','D','E','F','G','H']
}
/**
 * 判断是否为合法的身份证号码
 */
function isCard(card){
	var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
	if(card.length!=18){
		return {rt:false,msg:'身份证长度18位！'}; 
	}
	if(!regIdNo.test(card)){ 
	  return {rt:false,msg:'身份证格式格式！'}; 
	}
	return {rt:true,msg:'身份证验证通过！'};
}
function isWx(){//是否微信
	var ua = window.navigator.userAgent.toLowerCase();
	 if (ua.match(/MicroMessenger/i) == 'micromessenger') {
	     console.log("微信");
		 return true;
	 } else {
	     console.log("非微信");
		 return false;
	 }
}

function clearLogin(){//清除登录信息
	localStorage.removeItem('isLogin');
	localStorage.removeItem('userInfo');
	//location.reload();
	console.log('清除登录信息')	
	
}
function networkError(error){
	if(error.code=='ERR_NETWORK'){
		this.clearLogin();
		this.$router.push('/login');
	}
}
/**
 * @desc 防抖函数
 * @param fn 目标函数
 * @param wait 延迟执行毫秒数
*/
function debounce (fn, wait = 1000) {
	let timer = null;
	return function () {
		let now = !timer;
		timer && clearTimeout(timer);
		timer = setTimeout(() => {timer = null;}, wait);
		if (now) {
			fn.apply(this, arguments);
		}
    };
}
/**
 * @desc 节流函数
 * @param fn 目标函数
 * @param wait 延迟执行毫秒数
*/
function throttle (fn, wait = 1000) {
	let last = 0;
	return function () {
		let now = Date.now();
		if (now - last > wait) {
			fn.apply(this, arguments);
			last = now;
        }
    };
}
function getSign(param) {  // 获取签名   返回一个包含"?"的参数串
    var appKey = "Mr.whTrI2HHFL90.FSWW3E1Q";
    var securityKey = "2023Trkj96CD09FGJCd8k";
    var timeStamp = Date.parse(new Date())/1000;
	
    // 判断是否有参数
    if(param!=null && param.length>0) {
        param = "appKey=" + appKey + "&timeStamp=" + timeStamp + "&" + param;
    }else {
        param = "appKey=" + appKey + "&timeStamp=" + timeStamp;
    }
	var sign = calculateSign(param,securityKey);
	//console.log('sign',sign,timeStamp)
    return sign+'#$!'+timeStamp; 
	//return "?appKey="+appKey+"&timeStamp="+timeStamp+"&sign="+calculateSign(param,securityKey); 
}
// 生成sign
function calculateSign(param,securityKey) {
    var params = param.split("&");
	//console.log('param-old',decodeURIComponent(param));
	//剔除数组参数start
	var newParams = [];
	var len = params.length;
	if(len>0){
		var reg = new RegExp("^(.*)%5B(.*)%5D(.*)=(.*)$",'i');
		for(var i=0;i<len;i++){
			if(!reg.test(params[i])){
				var paramsDecode = decodeURIComponent(params[i]);
				params[i] = paramsDecode.length>1024 ? '' : paramsDecode;
				newParams.push(params[i])
			}
		}
		params = newParams;
	}
	
	//剔除数组参数end
	var regex = new RegExp("[\\s \\@/:%#^$&*]",'g');
	param = (params.sort().join("&")).replace(regex,""); 
	//console.log('param',param);
	return (MD5(param+securityKey)).toUpperCase(); 
}
function randStr(length=12){
  //length为所需长度，characters为所包含的所有字符，默认为字母+数字。
  var characters="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  characters = characters.split("");//分割字符。
  var result = "";//返回的结果。
  while(result.length<length) result+=characters[Math.round(Math.random()*characters.length)];
  return result;
}
function encryptRandStr(length=16){
  //length为所需长度，characters为所包含的所有字符，默认为字母+数字。
  var characters="ABCDEF0123456789abcdefg";
  characters = characters.split("");//分割字符。
  var result = "";//返回的结果。
  while(result.length<length) result+=characters[Math.round(Math.random()*characters.length)];
  return result;
}

//获取当前时间戳
function getTime() {
    return new Date().getTime();
}
function permissions(data,id) {
    for (let i = 0; i < data.length; i++) {
        const oItem = data[i];
        if (oItem.menu_id === id) {
            return oItem.children;
        } else {
            if (oItem.children && oItem.children.length) {
                const result = permissions(oItem.children,id);
                if (result) {
                    return result;
                }
            }
        }
    }
}
function menuBtns(data,id){
	var permissions = this.permissions(data,id);
	//console.log('permissions',permissions);
	var menuBtns = [];
	var len = permissions?permissions.length:0;
	if(len>0){
		for(var i=0;i<len;i++){
			if(!permissions[i].children){
				//var params = permissions[i].path.split('/');
				var params = permissions[i].path;
				//console.log('params',params);
				if(menuBtns.indexOf(params) == -1){
					menuBtns.push(params);
				}
			}
			
			
		}
	}
	//console.log('menuBtns',menuBtns);
	return menuBtns;
}
export default{
		isLogin,
		placeholderPic,
		platForm,
		isWx,
		alphabets,
		clearLogin,
		networkError,
		isCard,
		getSign,
		calculateSign,
		randStr,
		encryptRandStr,
		trimSpace,
		getTime,
		debounce,
		throttle,
		permissions,
		menuBtns,
}