import { createApp } from 'vue';
//import ElementPlus from 'element-plus'
import '/common/common.css'
import '/common/iconfont.css'
import 'element-plus/dist/index.css'
import 'nprogress/nprogress.css'
//import VueSocketIO from 'vue-socket.io'
//import SocketIO from 'socket.io-client'
import App from './App.vue'
import router from "./router"
import store from "./store"
import "./router/router-config"  // 路由守卫，做动态路由的地方
import {post,get,patch,put} from '../common/http.js';
import webtitle from 'vue-wechat-title';
//import VueWebsocket from "vue-websocket";
//import SocketIO from '../common/io'
// import {AMap,initAMapApiLoader} from '@vuemap/vue-amap';
// import '@vuemap/vue-amap/dist/style.css'
// initAMapApiLoader({
//     key: '9a83d9c61f967187ffe307f2dfb170fe',
// 	plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
// 	v: '1.4.4'
// })
// //这里的安全密钥不写会导致 行政区查询等API无法使用
// window._AMapSecurityConfig = {
//   securityJsCode: '582ef81a65a94002cb1eeae8c861e285',
// };
const app = createApp(App);

// import ElementPlus from 'element-plus'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
// app.use(ElementPlus, { locale:locale });
// 全局注册el-icon,start
import * as ElementPlusIcons from '@element-plus/icons-vue';
for (const [key, component] of Object.entries(ElementPlusIcons)) {
   app.component(key, component)
}
// 全局注册el-icon,end
//自定义图标start
import registerSvgIcon from "@/icons/index";
registerSvgIcon(app);
//自定义图标end
// //修改分页器默认文字start
// import zhCn from "element-plus/es/locale/lang/zh-cn"
// zhCn.el.pagination.total = "共 " + `{total}` + " 条";
// zhCn.el.pagination.goto = "跳转至";
// app.use(ElementPlus,{locale:zhCn});
// //修改分页器默认文字end
//定义全局变量
app.config.globalProperties.$post=post;
app.config.globalProperties.$get=get;
app.config.globalProperties.$patch=patch;
app.config.globalProperties.$put=put;
//app.config.globalProperties.$socket = VueWebsocket;
// app.use(new VueSocketIO({
//     debug: false,
//     connection: 'ws://127.0.0.1:2130',  //
// 	 options:{
// 	 	autoConncet:true,//关闭自动连接
// 		reconnectionAttempts: 5//设置最大重试次数
// 	 }
// }))
// app.use(
// 	new VueSocketIO({
// 	   // 生产环境需要切换成false
// 	  debug: true,
// 	  // 连接的后端地址  
// 	  connection: SocketIO('ws://127.0.0.1:2130'),
// 	  //allowEIO3:false,
// 	})
// )
app.use(webtitle)
app.use(router)
app.use(store)
//app.use(VueWebsocket, "ws://127.0.0.1:3120");
app.mount('#app')

// new Vue({
//   el: '#app',
//   router,
//   render: h => h(App)
// })
