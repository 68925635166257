import "core-js/modules/es.array.push.js";
import api from "./../../../../common/api";
import common from "./../../../../common/common.js";
import { ElMessage } from 'element-plus';
import Upload from '../../../components/upload';
// import { Plus } from '@element-plus/icons-vue';
import qs from 'qs';
export default {
  name: 'user_edit',
  components: {
    //Plus,
    Upload
  },
  props: {
    action: {
      type: String,
      value: 'add'
    },
    actionUrl: {
      type: String,
      value: '/admins/add'
    },
    userDataOne: {
      type: Object,
      value: {}
    }
  },
  created: function () {
    if (localStorage.getItem('isLogin')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        this.userInfo = userInfo;
        this.roles();
        if (this.action != 'add') {
          this.admins = this.userDataOne;
        }
      }
    }
  },
  data: function () {
    return {
      loading: false,
      loading_text: '加载中..',
      headers: {
        'Content-Type': 'multipart/form-data;'
      },
      uploading_loading: false,
      uploading_loading_text: '正在上传...',
      upload_url: api.upload(),
      sizeMax: 200 * 1024 * 1024,
      //头像图片质量大小
      config: {
        headers: {
          'Content-Type': 'multipart/form-data;'
        },
        timeout: 50000
      },
      // 添加请求头
      admins: {
        id: '',
        username: '',
        mobile: '',
        password_old: '',
        password: '',
        password_confirm: '',
        email: '',
        avatar: '',
        role_name: '',
        avatarFileList: []
      },
      roleData: [{
        label: '请选择',
        value: 0
      }],
      //角色列表
      userInfo: {} //当前用户信息
    };
  },

  methods: {
    getUploadFile: function (e) {
      this.admins.avatar = e[0].url;
    },
    submit: common.throttle(function () {
      //return false;
      var that = this;
      // var checkData = this.checkData();
      // if(!checkData){
      // 	return false;
      // }
      this.loading = true;
      var data = this.admins;
      // 异步更新数据
      this.$post(api.doMain() + that.actionUrl, qs.stringify(qs.parse(data))).then(function (res) {
        that.loading = false;
        var noLogout = false;
        if (res.rt == 1) {
          ElMessage({
            type: 'success',
            message: res.msg,
            showClose: true,
            duration: 500
          });
          setTimeout(function () {
            that.userInit(); //初始化
            that.$emit('userEdit_close', {});
            if (that.action == 'edit_oneself') {
              data.routes = that.userInfo.routes;
              localStorage.setItem('userInfo', JSON.stringify(data));
            }
          }, 500);
          noLogout = true;
        } else {
          //that.err[`${res.id}`] = res.msg;//动态变量赋值
          ElMessage({
            type: 'warning',
            message: res.msg,
            showClose: true,
            duration: 2000
          });
          if (res.page) {
            setTimeout(function () {
              common.clearLogin();
              that.$router.push(res.page);
            }, 300);
          }
        }
        that.$emit('noLogout', noLogout);
      }).catch(function (error) {
        setTimeout(function () {
          that.loading = false;
        }, 200);
        common.networkError(error);
      });
    }),
    uploadError: function (res) {
      console.log('error-res', res);
    },
    uploadSuccess: function () {
      //this.uploading_loading = false;
      //console.log('success');
    },
    upload_avatar: function (uploadfile) {
      var that = this;
      var data = new FormData();
      that.uploading_loading = true;
      data.append('file', uploadfile.file);
      data.append('file_type', 'images');
      //data.append('file_type','file');
      data.append('file_flag', 'userAvatar');
      this.$post(api.upload(), data, that.config).then(function (res) {
        console.log('res', res);
        setTimeout(function () {
          that.uploading_loading = false;
        }, 300);
        if (res.rt == 1) {
          var admins = that.admins;
          admins.avatar = res.url;
          that.admins = admins;
          console.log('admins', that.admins);
        } else {
          var msg = res.msg;
          if (msg) {
            ElMessage.error(msg);
          }
          if (res.page) {
            setTimeout(function () {
              common.clearLogin();
              that.$router.push(res.page);
            }, 300);
          }
        }
      }).catch(function (error) {
        setTimeout(function () {
          that.loading = false;
        }, 200);
        common.networkError(error);
      });
    },
    beforeAvatarUpload: function (rawFile) {
      console.log('rawFile.type', rawFile.type);
      var reg_img = /^image\/[jpeg|jpg|png|gif]+$/;
      if (!reg_img.test(rawFile.type)) {
        ElMessage.error('图片格式须为jpeg|jpg|png!');
        return false;
      } else if (rawFile.size > this.sizeMax) {
        ElMessage.error('图片质量须小于' + parseInt(this.sizeMax / 1024 / 1024) + 'M');
        return false;
      }
      return true;
    },
    roles: function () {
      var that = this;
      var data = {};
      // 异步更新数据
      this.$post(api.doMain() + '/role/tree_select', qs.stringify(qs.parse(data))).then(function (res) {
        if (res.rt == 1) {
          var roleData = [];
          var roles = res.roleTreeSelect;
          var len = roles.length;
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              roleData.push({
                value: roles[i].id,
                label: roles[i].role_name
              });
            }
            that.roleData = roleData;
          }
        } else {
          //that.err[`${res.id}`] = res.msg;//动态变量赋值
          ElMessage({
            type: 'warning',
            message: res.msg,
            showClose: true,
            duration: 2000
          });
          if (res.page) {
            setTimeout(function () {
              common.clearLogin();
              that.$router.push(res.page);
            }, 300);
          }
        }
      }).catch(function (error) {
        setTimeout(function () {
          that.loading = false;
        }, 200);
        common.networkError(error);
      });
    },
    cancel: function () {
      var admins = {
        id: this.admins.id,
        username: this.admins.username,
        mobile: this.admins.mobile,
        role_name: this.admins.role_name,
        password_old: '',
        password: '',
        password_confirm: '',
        email: '',
        avatar: ''
      };
      this.admins = admins;
    },
    userInit() {
      if (this.action == 'edit_oneself') {
        return false;
      }
      this.admins = {
        id: '',
        username: '',
        mobile: '',
        password_old: '',
        password: '',
        password_confirm: '',
        email: '',
        avatar: '',
        role_name: '',
        avatarFileList: []
      };
    },
    close: function () {
      this.$emit('userEdit_close', {});
    }
  }
};