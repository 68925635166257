import "core-js/modules/es.array.push.js";
import store from './../store/index.js';
import common from './../../common/common.js';
import userEdit from './../views/system/user/user_edit';
import { ElMessage } from 'element-plus';
export default {
  name: 'homeHeader',
  components: {
    userEdit
  },
  data: function () {
    return {
      //tabs:[],
      //tabValue:'',

      action: 'edit_oneself',
      actionUrl: '/admins/edit_oneself',
      actionTitle: '修改帐号',
      userDataOne: {},
      //修改的用户数据
      userEditShow: false,
      userInfo: {}
    };
  },
  created: function () {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.userInfo = userInfo;
      this.userDataOne = {
        username: userInfo.username,
        mobile: userInfo.mobile,
        email: userInfo.email,
        role: userInfo.role,
        role_name: userInfo.role_name,
        avatar: userInfo.avatar
      };
    }
  },
  computed: {
    tabs() {
      var tabs = store.state.tabs;
      return tabs;
    },
    tabValue: {
      get: function () {
        var tabValue = store.state.tabValue;
        return tabValue;
      },
      set: function () {}
    }
  },
  methods: {
    tabChange: function (activeName) {
      this.$router.push(activeName);
      store.commit('OnIsPath', activeName);
      //store.commit('OnIsPath',activeName);
      //console.log('activeName',activeName)
    },

    removeTab: function (targetName) {
      const tabs = this.tabs;
      if (tabs.length == 1) {
        return false;
      }
      let activeName = this.tabValue;
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          const nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            activeName = nextTab.name;
          }
        }
      });
      store.commit('onChangeTabs', tabs.filter(tab => tab.name !== targetName));
      store.commit('onChangeTabValue', activeName);
      this.$router.push(activeName);
    },
    isCollapse: function () {
      this.$emit('isCollapse', {});
    },
    message: function () {
      ElMessage({
        type: 'warning',
        message: '暂无系统消息',
        offset: '20',
        showClose: true,
        duration: 2000
      });
    },
    noLogout: function (e) {
      this.noLogoutData = e;
    },
    editSubmit: function () {
      this.$refs.userEdit.submit();
      if (this.noLogoutData) {
        this.logout();
      }
    },
    editCancel: function () {
      this.$refs.userEdit.cancel();
    },
    userEdit: function () {
      this.userEditShow = true;
    },
    userEdit_close: function () {
      this.userEditShow = false;
    },
    logout: function () {
      var that = this;
      this.$emit('isLoading', {
        value: true
      });
      common.clearLogin(); //清除登录记录
      setTimeout(function () {
        that.$emit('isLoading', {
          value: false
        });
        that.$router.push('/login');
      }, 500);
    }
  }
};