import common from './common.js';
import qs from 'qs'; 
import { ElMessage } from 'element-plus';
// 导入axios
import axios from "axios";
//1. 创建新的axios实例，
const service = axios.create({
// 这里不做baseURL处理,一个项目可能有多个不同的后台请求头
	timeout: 10 * 1000,
	retry: 2, //设置全局重试请求次数（最多重试几次请求）
	retryDelay: 1000, //设置全局请求间隔

});
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    var data = config.data; //数据转化,也可以使用qs转换
    //配置请求头 这里不做处理在http做具体处理
	var userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if(userInfo){
		//config.headers['Content-Type'] = 'application/json;charset=utf-8';
		config.headers["Authorization"] = userInfo.token+'^^'+userInfo.member_id;
		if(config.headers['Content-Type'] && config.headers['Content-Type'].toLowerCase()=='multipart/form-data;'){
			
			//上传文件时，过滤掉file文件属性，否则签名无法通过 start
			var jsonData = {};
			data.forEach((value, key) => jsonData[key] = value);
			delete jsonData.file;
			config.headers["Sign"] = common.getSign(qs.stringify(qs.parse(jsonData)));
			//end
		}else{
			
			config.headers["Sign"] = common.getSign(data);
		}
	}
	//console.log('config',config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//响应拦截器  
service.interceptors.response.use((res) => {
  return Promise.resolve(res);
}, (error) => {
	//超时处理 error.config是一个对象，包含上方create中设置的三个参数
	var config = error.config;
	if (!config || !config.retry) {
		return Promise.reject(error);
	}
	//如果有响应内容，就直接返回错误信息，不再发送请求
	if(error.response && error.response.data){
		return Promise.reject({type: "error", msg: error.response.data});
	}
	// __retryCount用来记录当前是第几次发送请求
	config.__retryCount = config.__retryCount || 0;
	// 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
	if (config.__retryCount >= config.retry) {
		if (error && error.response) {
			// 2.根据响应码具体处理
			switch (error.response.status) {
				case 400:
				  error.message = "错误请求";
				  break;
				case 401:
				  error.message = "未授权，请重新登录";
				  break;
				case 403:
				  error.message = "拒绝访问";
				  break;
				case 404:
				  error.message = "请求错误,未找到该资源";
				  break;
				case 405:
				  error.message = "请求方法未允许";
				  break;
				case 408:
				  error.message = "请求超时";
				  break;
				case 500:
				  error.message = "服务器端出错";
				  break;
				case 501:
				  error.message = "网络未实现";
				  break;
				case 502:
				  error.message = "网络错误";
				  break;
				case 503:
				  error.message = "服务不可用";
				  break;
				case 504:
				  error.message = "网络超时";
				  break;
				case 505:
				  error.message = "http版本不支持该请求";
				  break;
				default:
				  error.message = `连接错误${error.code}`;
			}
			//console.log('error',error);
			ElMessage.error(error.message);
			//console.log('error',JSON.stringify(error).includes("timeout"));
			//如果不需要错误处理，以上的处理过程都可省略
			
		} else {
		  // 超时处理
			if (JSON.stringify(error).includes("timeout")) {
				ElMessage.error("服务器响应超时，请刷新当前页");
			}
		  
		}
		/***** 处理结束 *****/
		//如果不需要错误处理，以上的处理过程都可省略
		return Promise.resolve(error.response);
	}
  
  // 记录请求次数+1
  config.__retryCount += 1;
  // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });
 
  // 再次发送请求
  return backoff.then(function () {
    return service(config);
  });
})

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url,data={},config){
  return new Promise((resolve,reject) => {
    service.get(url,data,config)
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url,data = {},config){
   return new Promise((resolve,reject) => {
     service.post(url,data,config)
          .then(response => {
			  //console.log('response',response);
			  if(response){
				  resolve(response.data);
			  }
				
          },err => {
            reject(err)
			//console.log('err',err);
          })
   })
 }

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    service.patch(url,data)
         .then(response => {
           if(response){
           		resolve(response.data);
           }
         },err => {
           reject(err)
         })
  })
}

 /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
  return new Promise((resolve,reject) => {
    service.put(url,data)
         .then(response => {
           if(response){
           		resolve(response.data);
           }
         },err => {
           reject(err)
         })
  })
}
