import menuLeft from './../components/menuLeft';
import Header from './../components/header';
//import socketIO from './../components/socket';
import { ElMessageBox } from 'element-plus';
export default {
  name: 'HomeIndex',
  components: {
    menuLeft,
    Header
    //socketIO,
  },

  data: function () {
    return {
      title: '首 页',
      isCollapse: false,
      menuLeftWidth: '',
      menuLeftWidthLong: '210px',
      menuLeftWidthShort: '64px',
      loading: false,
      emit: 'update_online_count',
      //回调函数名
      update_online_count: 0,
      userInfo: {} //当前帐号信息
    };
  },

  computed: {
    routes() {
      //console.log('this.$router.options.routes',this.$router.options.routes);
      return this.$router.options.routes;
    }
  },
  created: function () {
    //var that = this;
    document.title = this.title;
    var isCollapse = localStorage.getItem('isCollapse');
    this.isCollapse = isCollapse == 'true' ? true : false;
    this.menuLeftWidth = isCollapse == 'true' ? this.menuLeftWidthShort : this.menuLeftWidthLong;
    this.clientHeight = document.documentElement.clientHeight + 'px';
    if (localStorage.getItem('isLogin')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        this.userInfo = userInfo;
        //var socketIO = that.$refs.socketIO;
        // setTimeout(function(){
        // 		  that.$refs.socketIO.socketConnect({group_id:'n1',member_id:that.userInfo.member_id});
        // 		  that.$refs.socketIO.socketMessage(that.emit,false);
        // 		  that.$refs.socketIO.socketMessage('new_msg','alert');
        // },2000);
      }
    }
  },

  methods: {
    newMessage: function (e) {
      console.log('e', e);
      var emit = e.emit;
      var popup = e.popup;
      this.$data[`${emit}`] = e.msg; //data的动态变量赋值
      if (popup) {
        ElMessageBox.alert(e.msg, '系统消息', {
          confirmButtonText: '确 认',
          //确定按钮的文本内容
          dangerouslyUseHTMLString: true,
          //是否将 message 作为 HTML 片段处理
          autofocus: false,
          //是否自动获得焦点
          draggable: true
        });
      }
    },
    isLoading: function (e) {
      //console.log('loading-index',e.value);
      this.loading = e.value;
    },
    collapseChange: function () {
      var that = this;
      var menuLeftWidth = this.menuLeftWidthLong;
      var isCollapse = localStorage.getItem('isCollapse');
      isCollapse = isCollapse == 'true' ? false : true;
      this.isCollapse = isCollapse;
      if (isCollapse == true) {
        menuLeftWidth = this.menuLeftWidthShort;
        setTimeout(function () {
          that.menuLeftWidth = menuLeftWidth;
        }, 250);
      } else {
        this.menuLeftWidth = menuLeftWidth;
      }
      localStorage.setItem('isCollapse', isCollapse);
      localStorage.setItem('menuLeftWidth', menuLeftWidth);
    }
  }
};