import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5894f68c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.External ? (_openBlock(), _createElementBlock("div", _mergeProps({
    key: 0,
    style: _ctx.styleExternalIcon,
    class: "svg-external-icon svg-icon"
  }, _ctx.$attrs), null, 16)) : (_openBlock(), _createElementBlock("svg", _mergeProps({
    key: 1,
    class: _ctx.svgClass,
    "aria-hidden": "true"
  }, _ctx.$attrs), [_createElementVNode("use", {
    "xlink:href": _ctx.symbolId
  }, null, 8, _hoisted_1)], 16));
}