import "core-js/modules/es.array.push.js";
import api from "./../../common/api";
import common from "./../../common/common.js";
import { ElMessage } from 'element-plus';
import * as qiniu from 'qiniu-js';
import qs from 'qs';
export default {
  name: 'component_upload',
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    isButton: {
      default: false,
      type: Boolean
    },
    showFileList: {
      default: true,
      type: Boolean
    },
    filesData: {
      type: Array,
      value: []
    },
    uploadTitle: {
      default: '上 传',
      type: String
    },
    isProgress: {
      // 关闭进度条
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    limit: {
      //允许上传文件个数
      default: 1,
      type: Number
    },
    uploadSaveLocal: {
      default: true,
      type: Boolean
    },
    imageUrl: {
      type: String,
      default: ''
    },
    file_type: {
      type: String,
      default: 'file'
    },
    storageType: {
      type: String,
      default: 'qiniu'
    },
    icon: {
      type: String,
      default: 'upload'
    },
    buttonThemeType: {
      type: String,
      default: 'primary'
    },
    plain: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      // uploading_loading:false,
      // uploading_loading_text:'正在上传...',
      loadProgress: 0,
      // 动态显示进度条
      buttonText: "暂停上传",
      doMain: 'http://training.zzn.shanyangyoupin.com',
      imgUrl: '',
      filePath: '',
      fileList: [],
      token: '',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data;'
        },
        timeout: 30000
      } // 添
    };
  },

  created: function () {
    this.getToken();
    this.progressFlag = this.isProgress ? true : false;
    this.imgUrl = this.imageUrl;
    this.fileList = this.filesData;
    //console.log('filesData',this.filesData);
    //console.log('this.fileList',this.fileList);
    //console.log('imgUrl',this.imgUrl);
  },

  methods: {
    //移除文件
    removeFile: function () {
      this.progressFlag = false;
      this.loadProgress = 0;
      this.buttonText = "暂停上传";
    },
    //上传之前
    handleBeforeUpload: function () {
      //上传之前重新获取uptoken以免过期
      if (this.limit == 1) {
        this.fileList = [];
      }
      //console.log('上传之前',this.fileList);
      //console.log('file',file)
      this.uploading_loading = true;
      this.progressFlag = true;
    },
    // 上传图片成功
    // handleUploadSuccess:function(response, file, fileList) {
    // 	this.$refs.uploadRef.clearFiles();//清除文件

    // },
    uploadExceed: function (files) {
      //console.log('files',files)
      //console.log('limit2',this.limit);
      //console.log('length2',this.fileList.length);
      //console.log('fileList2',this.fileList);
      if (this.limit < files.length) {
        ElMessage.error('无法上传，每次最大上传' + this.limit + '个文件！');
        return false;
      }
      if (this.limit <= this.fileList.length) {
        //console.log('改变','limit='+this.limit,'length='+this.fileList.length);
      }
    },
    uploadChange: function () {
      console.log('清除文件');
      this.$refs.uploadRef.clearFiles(); //清除文件
    },

    //
    uploadWithSDK: function (token, putExtra, config, domain, file) {
      var that = this;
      if (file) {
        var type = file.name.split('.');
        var filePath = common.getTime() + '' + common.randStr(6) + '.' + type[type.length - 1];
        this.filePath = filePath;
        // 设置next,error,compvare对应的操作，分别处理相应的进度信息，错误信息，以及完成后的操作
        var error = function (err) {
          console.log(err);
        };
        var compvare = function (res) {
          console.log(res);
        };
        var next = function (response) {
          that.loadProgress = Math.floor(response.total.percent);
          //console.log('loadProgree',that.loadProgress);
          if (that.loadProgress == 100) {
            that.getFilePath(filePath, '', '', file.name);
          }
        };
        var subObject = {
          next: next,
          error: error,
          compvare: compvare
        };
        // 调用sdk上传接口获得相应的observable，控制上传和暂停
        var observable = qiniu.upload(file, this.filePath, token, putExtra, config);
        var subscription = observable.subscribe(subObject);

        //监听按钮事件，控制上传
        that.$nextTick(function () {
          document.getElementById("myBtn").addEventListener("click", function () {
            if (that.buttonText == "暂停上传") {
              that.buttonText = "继续上传";
              subscription.unsubscribe();
            } else if (that.buttonText == "继续上传") {
              that.buttonText = "暂停上传";
              subscription = observable.subscribe(subObject);
            }
          });
        });
      }
    },
    upload_local: function (uploadfile) {
      var that = this;
      var data = new FormData();
      that.uploading_loading = true;
      data.append('file', uploadfile.file);
      data.append('file_type', this.file_type);
      data.append('file_flag', 'updateTestData');
      data.append('storageType', this.storageType);
      //console.log('data',data);
      this.$post(api.upload(), data, that.config).then(function (res) {
        if (res.rt == 1) {
          that.getFilePath(res.filename, res.path, res.url);
          //that.uploadMobile(res.path,res.filename,uploadfile['file']);
          //console.log('res',res);
        } else {
          var msg = res.msg;
          if (msg) {
            ElMessage.error(msg);
          }
          if (res.page) {
            setTimeout(function () {
              common.clearLogin();
              that.$router.push(res.page);
            }, 300);
          }
        }
      }).catch(function (error) {
        setTimeout(function () {
          that.loading = false;
        }, 200);
        common.networkError(error);
      });
    },
    upload_qiniu: function (param) {
      var file = param.file;
      //外链域名
      var domain = this.doMain;
      //可以配置分片大小等配置，具体查看官方文档
      var config = {
        useCdnDomain: true,
        //是否使用CDN加速域名
        disableStatisticsReport: false,
        //是否禁用日志报告
        retryCount: 6 //上传自动重试次数
      };

      var putExtra = {
        customVars: {}
      };
      this.uploadWithSDK(this.token, putExtra, config, domain, file);
    },
    requestFile: function (param) {
      if (this.uploadSaveLocal == true) {
        this.upload_local(param);
      } else {
        this.upload_qiniu(param);
      }
    },
    getFilePath: function (filePath, path = '', url = '', filename_original = '') {
      //console.log(111);
      this.uploading_loading = false;
      url = this.uploadSaveLocal == true ? url : this.doMain + '/' + filePath;
      var fileList = this.fileList ? this.fileList : [];
      fileList.push({
        url: url,
        name: filePath,
        path: path,
        filename_original: filename_original
      });
      if (this.limit <= fileList.length) {
        fileList = fileList.slice(-this.limit);
      }
      //console.log(222);
      this.fileList = fileList;
      this.$emit('getUploadFile', this.fileList);
      var reg_img = /\.[jpeg|jpg|png|gif]+$/;
      if (reg_img.test(url)) {
        this.imgUrl = url;
      }
    },
    getToken: function () {
      var that = this;
      var data = {};
      // 异步更新数据
      this.$post(api.doMain() + '/upload/qiniuToken', qs.stringify(qs.parse(data))).then(function (res) {
        if (res.rt == 1) {
          that.token = res.token;
        } else {
          //that.err[`${res.id}`] = res.msg;//动态变量赋值
          ElMessage({
            type: 'warning',
            message: res.msg,
            showClose: true,
            duration: 2000
          });
          if (res.page) {
            setTimeout(function () {
              common.clearLogin();
              that.$router.push(res.page);
            }, 300);
          }
        }
      }).catch(function (error) {
        setTimeout(function () {
          that.loading = false;
        }, 200);
        common.networkError(error);
      });
    }
  }
};