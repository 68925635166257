import { createStore } from 'vuex'
const store = createStore({
    state: {
        routes: "",// routes
		tabs:[],
		is_path:"",
		tabValue:""
    },
    mutations: {
        // 获取已处理完成的routes数据mutaions 
        onChangeRoutes(state, data) {
            state.routes = data
			
        },
		OnIsPath(state,data){
			state.is_path = data;
		},
		onChangeTabs(state,data){
			state.tabs = data;
			//console.log('onChangeTabs',state.tabValue);
		},
		addTabs(state,data){
			state.tabValue = data.name;
			state.tabs.push(data)
			var arr = state.tabs;
			const map = new Map()
			const tabs = arr.filter(key => !map.has(key.name) && map.set(key.name, 1)) // 这里对name属性进行去重
			state.tabs = tabs;
			//console.log('state.tabs',state.tabs);
			//console.log('state-tabs',state.tabs);
		},
		onChangeTabValue(state,data){
			state.tabValue = data;
			
			//console.log('onChangeTabValue',data)
		}
    }
})
export default store