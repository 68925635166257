import { createRouter, createWebHashHistory} from 'vue-router'
//import common from '../../common/common.js'
//common.clearLogin();
const routes = [
	{ path: "/logout", meta: { title: "注 销" }, component: () => import("../views/logout/index"), hidden: true,children:[] },
	{ path: "/login", meta: { title: "登 录" }, component: () => import("../views/login/index"), hidden: true,children:[] },
	// { path: "/", meta: { title: "登 录" }, component: () => import("../views/system/user/test"), hidden: true,children:[
	// 	{ name: "index", path: "/system/user/test", meta: { title: "index" }, component: () => import("../views/system/user/test") },
	// ] },
	// {
	//         path: '/:catchAll(.*)',
	//         name: '404',
	//         component: () => import('@/views/error/index.vue')
	// },
	//反馈界面
	    //{ path: '/error', component: Error ,meta: {title: "出错了！当前界面不存在"}},

  //{ path: "/", meta: { title: "首页" }, component: () => import("../views/index/index"), hidden: true,children:[] },
  // { name: "/", path: "/", component: Layout, redirect: "/index", hidden: false, children: [
  //     { name: "index", path: "/index", meta: { title: "index" }, component: () => import("../views/index/index") },
  // ]},
//   { name: "/form", path: "/form", component: Layout, redirect: "/form/index", hidden: false, children: [
//       { name: "/form/index", path: "/form/index", meta: { title: "form" }, component: () => import("../views/form/index") }
//   ]},
//   { name: "/example", path: "/example", component: Layout, redirect: "/example/tree", meta: { title: "example" }, hidden: false, children: [
// 	  { name: "/tree", path: "/example/tree", meta: { title: "tree" }, component: () => import("../views/tree/index") },
// 	  { name: "/copy", path: "/example/copy", meta: { title: "copy" }, component: () => import("../views/tree/copy") }
//   ] },
//   { name: "/table", path: "/table", component: Layout, redirect: "/table/index", hidden: false, children: [
// 	  { name: "/table/index", path: "/table/index", meta: { title: "table" }, component: () => import("../views/table/index") }
//   ] },
//   { name: "/admin", path: "/admin", component: Layout, redirect: "/admin/index", hidden: false, children: [
// 	{ name: "/admin/index", path: "/admin/index", meta: { title: "admin" }, component: () => import("../views/admin/index") }
// ] },
// { name: "/people", path: "/people", component: Layout, redirect: "/people/index", hidden: false, children: [
// 	{ name: "/people/index", path: "/people/index", meta: { title: "people" }, component: () => import("../views/people/index") }
// ] }
]
// 这里不要用VueRouter.来使用方法 直接用导入的
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
	//history: createWebHistory("/"),//去掉#
    routes, // `routes: routes` 的缩写
});

export default router
