// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import { isExternal } from '../../../utils/validate.js';
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const External = computed(() => {
      return isExternal(props.iconClass.replace('.svg', ''));
    });
    const symbolId = computed(() => {
      return `#icon-${props.iconClass.replace('.svg', '')}`;
    });
    const svgClass = computed(() => {
      if (props.className) {
        return 'svg-icon ' + props.className;
      } else {
        return 'svg-icon';
      }
    });
    const styleExternalIcon = computed(() => {
      return {
        mask: `url(${props.iconClass.replace('.svg', '')}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${props.iconClass.replace('.svg', '')}) no-repeat 50% 50%`
      };
    });
    return {
      External,
      symbolId,
      svgClass,
      styleExternalIcon
    };
  }
});