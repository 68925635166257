import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0db58072"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-layout",
  "element-loading-text": "正在退出..."
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_menuLeft = _resolveComponent("menuLeft");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_footer = _resolveComponent("el-footer");
  const _component_el_container = _resolveComponent("el-container");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      width: _ctx.menuLeftWidth,
      height: _ctx.clientHeight
    }, {
      default: _withCtx(() => [_createVNode(_component_menuLeft, {
        isCollapse: _ctx.isCollapse,
        menuLeftWidth: _ctx.menuLeftWidth,
        clientHeight: _ctx.clientHeight,
        routes: $options.routes
      }, null, 8, ["isCollapse", "menuLeftWidth", "clientHeight", "routes"])]),
      _: 1
    }, 8, ["width", "height"]), _createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_Header, {
        onIsCollapse: $options.collapseChange,
        onIsLoading: $options.isLoading
      }, null, 8, ["onIsCollapse", "onIsLoading"]), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }), _createVNode(_component_el_footer, {
        style: {
          "position": "fixed",
          "right": "0",
          "bottom": "0px",
          "font-family": "微软雅黑",
          "color": "#555",
          "font-size": "14px",
          "height": "30px",
          "line-height": "30px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(" 技术支持：王辉 反馈邮箱：627271099@qq.com ")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_loading, _ctx.loading]]);
}