import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_Upload = _resolveComponent("Upload");
  const _component_el_form = _resolveComponent("el-form");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.admins,
    "label-width": "90px",
    "element-loading-text": _ctx.loading_text
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "用户名"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.admins.username = $event),
        placeholder: "用户名必填",
        disabled: $props.action == 'edit_oneself' ? true : false
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "手机号"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.mobile,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.admins.mobile = $event),
        placeholder: "手机号必填"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "邮箱"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.admins.email = $event),
        placeholder: "邮箱必填"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), ($props.action != 'edit_oneself' ? false : true) ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 0,
      label: "旧密码"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.password_old,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.admins.password_old = $event),
        type: "password",
        autocomplete: "new-password",
        placeholder: "不修改密码可留空"
      }, null, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
      label: "密码"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.password,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.admins.password = $event),
        type: "password",
        autocomplete: "new-password",
        placeholder: $props.action == 'add' ? '密码必须填写' : '不修改密码可留空',
        "show-password": ""
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "确认密码"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.admins.password_confirm,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.admins.password_confirm = $event),
        type: "password",
        autocomplete: "new-password",
        placeholder: $props.action == 'add' ? '确认密码必须填写' : '不修改密码可留空',
        "show-password": ""
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "权限角色"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        class: "inputW180",
        modelValue: _ctx.admins.role,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.admins.role = $event),
        disabled: $props.action == 'edit_oneself' ? true : false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleData, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _withDirectives((_openBlock(), _createBlock(_component_el_form_item, {
      label: "上传头像",
      "element-loading-text": _ctx.uploading_loading_text
    }, {
      default: _withCtx(() => [_createVNode(_component_Upload, {
        ref: "Upload",
        onGetUploadFile: $options.getUploadFile,
        limit: 1,
        icon: 'upload',
        uploadSaveLocal: false,
        isShow: true,
        filesData: _ctx.admins.avatarFileList,
        imageUrl: _ctx.admins.avatar,
        isButton: false,
        file_type: 'images',
        uploadTitle: '上传头像',
        showFileList: false,
        multiple: false,
        isProgress: false
      }, null, 8, ["onGetUploadFile", "filesData", "imageUrl"])]),
      _: 1
    }, 8, ["element-loading-text"])), [[_directive_loading, _ctx.uploading_loading]])]),
    _: 1
  }, 8, ["model", "element-loading-text"])), [[_directive_loading, _ctx.loading]])]);
}